//@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html {
    margin: 0px;
    padding: 0px;
}

body {
    color: #191919;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    -webkit-font-smoothing: antialiased;
    margin: 0px;
    padding: 86px 0px 0px 0px;
    height: 100%;
    overflow: auto;
    background: #FFFFFF;
}

input {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    -webkit-font-smoothing: antialiased;
    padding: 2px 6px 2px 6px;
    color: #F58220;
}

select {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    -webkit-font-smoothing: antialiased;
    padding: 2px 6px 2px 6px;
    color: #F58220;
}

textarea {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    -webkit-font-smoothing: antialiased;
    padding: 2px 6px 2px 6px;
    color: #F58220;
    height: 108px;
}

h1 {
    margin: 0px 0px 26px 0px;
    font-size: 1.8em;
    font-weight: 500;
    color: #303030;
}

h2 {
    margin: 20px 0px 0px 0px;
    font-size: 1.8em;
    line-height: 1.0em;
    font-weight: 500;
}

h3 {
    margin: 32px 0px 3px 0px;
    font-size: 1.3em;
    font-weight: 500;
}

h4 {
    margin: 12px 0px 0px 0px;
    font-size: 1.0em;
}

h5 {
    margin: 12px 0px 0px 0px;
    font-size: 1.0em;
    font-weight: normal;
    color: #007932;
}

h6 {
    margin: 20px 0px 0px 0px;
    font-size: 0.9rem;
    font-style: italic;
}

hr {
    margin: 26px 0px 16px 0px;
    border-color: #EAEAEA;
    border-width: 1px;
    border-width: 1px 0px 0px 0px;
}

p {
    margin: 12px 0px 0px 0px;
}

ul {
    margin-top: 6px;
    margin-bottom: 6px;
}

li {
    margin-top: 6px;
}

dd {
    margin: 10px 0px 0px 12px;
}

a {
    color: #64add1;
    text-decoration: none;
}

a:hover {
    color: #DD634B;
}

.hidden {
    display: none !important;
}

.topline {
    border-top: solid 1px #007932;
}

.home-page-text {
    width: 80%;
    margin: 40px auto auto auto;
}

.home-page-text ul {
    margin: 20px 0px 0px 32px;
}

#cpg-content {
    width: 100%;
    height: 100%;
    margin-left: 90px;
}

#cpg-content.wide {
    margin-left: 260px;
    transition: all 0.3s;
}

#page-content {
    padding: 16px 10px 10px 20px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    border-top: solid 1px #CCCCCC;
}

.table .thead-light th {
    background-color: rgba(251, 176, 64, .7);
    border: solid 1px #FFFFFF;
    color: #212529;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    font-weight: normal;
}

.table .thead-admin th {
    background-color: #CCCCCC;
    border: solid 1px #FFFFFF;
    color: #212529;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    font-weight: normal;
    text-align: center;
}

.admin-checkbox-col {
    width: 120px;
    text-align: center;
}

.editable {
    cursor: pointer;
}

.editable:hover {
    color: #FFFFFF;
    background-color: rgba(251, 176, 64, .8);
}

/* ----------------------------- */
/* COLORS
/* ----------------------------- */
.spf-color {
    background-color: #898989;
}

.spf-default {
    background-color: #898989;
}

.orange {
    color: #F58220 !important;
}

.orange-bg {
    background-color: #F58220;
    color: #FFFFFF;
}

.green {
    color: #007932 !important;
}

.green-bg,
.spf-plan:hover,
.color-plan {
    background-color: #007932;
    color: #FFFFFF;
}

.blue {
    color: #64add1;
}

.blue-bg,
.spf-capacity:hover,
.color-capacity {
    background-color: #64add1;
    color: #FFFFFF;
}

.red {
    color: #dd634b;
}

.red-bg,
.spf-assessment:hover,
.color-assessment {
    background-color: #dd634b;
    color: #FFFFFF;
}

.yellow {
    color: #fbb040;
}

.yellow-bg,
.spf-implement:hover,
.color-implement {
    background-color: #fbb040;
    color: #FFFFFF;
}

.purple {
    color: #6d70a6;
}

.purple-bk,
.spf-evaluate:hover,
.color-evaluate {
    background-color: #6d70a6;
    color: #FFFFFF;
}

.white {
    color: #FFFFFF;
}

.lt-grey {
    color: #D9D9D9;
}

.grey {
    color: #808080;
}

.priority {
    color: #FFD700;
}

.color-active {
    color: #595959;
}

.spf-review:hover,
.color-review {
    background-color: #E2AB58;
}

.spf-accounts {
    background-color: #E0E0E0;
}

.grey-bg,
.spf-accounts:hover,
.color-account {
    background-color: #898989;
}

.color-assessment i,
.color-capacity i,
.color-plan i,
.color-implement i,
.color-review i,
.color-evaluate i,
.color-account i {
    color: #FFFFFF !important;
}

.color-assessment h4,
.color-capacity h4,
.color-plan h4,
.color-implement h4,
.color-review h4,
.color-evaluate h4,
.color-account h4 {
    color: #FFFFFF !important;
}

.color-assessment .spf-text,
.color-capacity .spf-text,
.color-plan .spf-text,
.color-implement .spf-text,
.color-review .spf-text,
.color-evaluate .spf-text,
.color-account .spf-text {
    color: #FFFFFF !important;
}


/* ----------------------------- */
/* BUTTONS
/* ----------------------------- */
.cpg-buttons {
    float: right;
    margin: 0px 12px 6px 6px;
}

/**
 * These have been moved to app.scss, easier to maintain in a SCSS file, since they're overwriting bootstrap.
 */

/*.btn-primary {*/
/*    font-size: 1.0em !important;*/
/*    background-color: #F58220 !important;*/
/*    border-color: #723602 !important;*/
/*    border-radius: 6px !important;*/
/*    padding: 0.175rem 0.75rem 0.125rem 0.75rem;*/
/*    color: #FFFFFF !important;*/
/*}*/

/*.btn-primary:hover {*/
/*    background-color: #696969;*/
/*}*/

/*.btn-secondary {*/
/*    background-color: #A3A3A3;*/
/*    font-size: 1.0em;*/
/*    border-radius: 6px;*/
/*    padding: 0.175rem 0.75rem 0.125rem 0.75rem;*/
/*}*/

/*.btn-danger {*/
/*    font-size: 1.0em;*/
/*    background-color: #dd634b;*/
/*    border-color: #942009;*/
/*    border-radius: 6px;*/
/*    padding: 0.175rem 0.75rem 0.125rem 0.75rem;*/
/*}*/

/*.btn-info {*/
/*    font-size: 0.9em;*/
/*    background-color: #F58220;*/
/*    border-color: #723602;*/
/*    border-radius: 4px;*/
/*    padding: 0.05rem 0.5rem 0.05rem 0.5rem;*/
/*}*/

/*.btn-light {*/
/*    font-size: 1.0em;*/
/*    background-color: #E9E9E9 !important;*/
/*    border-color: #FFFFFF !important;*/
/*    color: #696969 !important;*/
/*    border-radius: 6px;*/
/*    padding: 0.175rem 0.75rem 0.125rem 0.75rem;*/
/*}*/

/*.btn-light:hover {*/
/*    color: #900000 !important;*/
/*}*/

/* ----------------------------- */
/* CARDS
/* ----------------------------- */
.card-header {
    background-color: rgba(251, 176, 64, .7);
    padding: 0.5rem 1.2rem;
}

/* ----------------------------- */
/* DATA TABLE
/* ----------------------------- */

#activityTable,
#capacityTable {
    border-collapse: separate;
    border-spacing: 1px;
}

#activityTable_length {
    font-size: 0.9em !important;
    margin-top: 9px;
}

#activityTable_length select {
    font-size: 0.9em;
    line-height: 1em;
}

#activityTable_length option {
    min-height: 1em;
    font-size: 0.9em;
    line-height: 1em;
}

.dataTables_wrapper .dataTables_info {
    font-size: 0.8em !important;
    margin-bottom: 6px;
    font-style: italic;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    font-size: 0.9em !important;
    padding: .2em .5em !important;
    margin-top: 4px;
}

#activityTable td,
#capacityTable td {
    border: solid 1px #CCCCCC;
}

#activityTable th,
#capacityTable th {
    background-color: #FFFFFF;
}

.dtrg-group {
    height: 28px !important;
}

.dtrg-group td {
    background-color: #fbb040 !important;
}

#capacityTable .dtrg-group td {
    background-color: rgba(100, 173, 209, .9) !important;
    color: #FFFFFF !important;
}

#activityTable .dtrg-group td {
    background-color: rgba(251, 176, 64, .8) !important;
    color: #FFFFFF !important;
}

table.dataTable.clickable tbody tr {
    cursor: pointer;
    height: 86px;
}

table.dataTable tbody tr:hover {
    background-color: rgba(147, 149, 152, .4) !important;
}

div.dataTables_scrollBody>table>tbody>tr>td {
    vertical-align: top !important;
}

div.dataTables_wrapper span.select-info {
    display: none !important;
}

.dataTables_wrapper .dataTables_filter {
    margin-left: 12px;
    float: left !important;
}

.dt-button {
    font-size: 1.0em !important;
    border-radius: 4px !important;
    padding: 0.175rem 0.75rem 0.125rem 0.75rem;
}

.dt-button:hover {
    background-color: #F58220 !important;
    color: #FFFFFF;
}

.dt-button-collection .dt-button {
    background-color: #FFFFFF !important;
    border-radius: 2px !important;
    border-color: #CCCCCC !important;
    color: #595959 !important;
}

button.dt-button {
    padding: .2em .5em !important;
}

/* ----------------------------- */
/* INPUT FORMS
/* ----------------------------- */
.form-block {
    border: dotted 1px #909090;
    margin-top: 24px;
    position: relative;
    padding: 14px 12px 12px 12px;
}

.form-block-fixed {
    border: none;
    position: relative;
    padding: 14px 12px 12px 12px;
}

.form-block h4,
.form-block-fixed h4 {
    position: absolute;
    top: -20px;
    left: 20px;
    background-color: #FFFFFF;
    font-size: 0.9em;
    font-style: normal;
    padding: 0px 10px 0px 6px;
}

.form-item-nonflex {
    margin: 20px 0px 9px 0px;
    padding-right: 14px;
    width: 100%;
}

.form-item,
.form-item-fixed {
    margin: 20px 0px 9px 0px;
    padding-right: 14px;
    width: 100%;
    display: flex;
}

.form-item label,
.form-item-fixed label {
    flex: 0 0 220px;
    padding-top: 6px;
}

#accountSelector .form-item label,
.form-item-fixed label {
    flex: 0 0 120px;
    padding-top: 6px;
}

.form-item .input-full,
.form-item-fixed .input-full {
    flex: 1 0 200px;
}

.form-item .input-mid {
    flex: 0 0 50%;
}

.form-item .input-phone {
    flex: 0 1 100px;
}

.form-item .input-zip {
    flex: 0 0 60px;
}

.form-item .input-date {
    flex: 0 0 80px !important;
}

.form-item .input-number {
    flex: 0 1 60px;
    text-align: right;
}

.form-item em {
    flex: 1 1 100px;
    padding-top: 6px;
    padding-left: 10px;
    color: #909090;
}

.form-item textarea,
.form-item-fixed textarea {
    flex: 1 0 auto;
    height: 100px;
    border-color: #909090;
    border-radius: 4px;
}

.form-item input,
.form-item select:not([multiple]) {
    max-height: 35px;
}

.label-wide {
    flex: 0 0 162px !important;
}

.form-message {
    font-style: italic;
    margin: 9px 0px 9px 0px;
}

.required {
    font-size: 14px;
    color: #F58220;
    padding-right: 3px;
    vertical-align: middle;
}

.optional {
    font-size: 14px;
    color: #A9A9A9;
    padding-right: 3px;
    vertical-align: middle;
}

.entry-error {
    color: #dd634b;
}

.read-only {
    color: #393939;
    background-color: #F0F0F0;
    border: solid 1px #CCCCCC;
    border-radius: 3px;
}

hr {
    margin: 32px 0px 6px 0px;
    border-top: 1px dotted #909090;
}

.input-message {
    font-size: 0.85em;
    font-style: italic;
    margin-left: 120px;
    color: #393939;
}

.no-data-message {
    font-style: italic;
    margin-top: 4px;
    margin-left: 10px;
}

.actions-box {
    border-left: dotted 1px #909090;
    margin-top: 10px;
    text-align: center;
}

.actions-box button {
    width: 100%;
    margin-bottom: 9px;
}

.cpg-date {
    width: 126px;
    font-size: 18px;
    padding: 0.0rem 0.75rem;
    line-height: 1.0;
    height: auto;
    color: #F58220;
}

.form-subblock {
    border: solid 1px #909090;
    border-radius: 4px;
    margin: 20px 0px 6px 0px;
    padding: 9px;
    width: 100%;
    background-color: #EAEAEA;
}

.staff-options-submenu {
    margin: 6px 0px 0px 12px;
}

.staff-entered {
    color: #909090 !important;
}

/* ----------------------------- */
/* CUSTOM CHECKBOX
/* ----------------------------- */
.checkmark-item {
    margin-top: 12px;
}

.cb-container {
    width: 100%;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cb-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #E0E0E0;
    border: solid 1px #909090;
    border-radius: 4px;
}

.cb-container:hover input~.checkmark {
    background-color: #999999;
}

.cb-container input:checked~.checkmark {
    background-color: #64add1;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.cb-container input:checked~.checkmark:after {
    display: block;
}

.cb-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.disabled-checkbox {
    margin: 0px 0px 12px 0px;
}

.disabled-checkbox span {
    padding-left: 6px;
}

.disabled-checkbox input {
    display: none;
}

/* ----------------------------- */
/* CHECKBOX TABLE
/* ----------------------------- */
.checkbox-table tr {
    height: 45px;
}

.checkbox-table .checkmark-item {
    margin-top: -12px;
    margin-left: 38px;
    padding-right: 0px;
}

.sub-section {
    color: #505050;
    padding: 0.75rem 0.75rem 0rem 2rem !important;
}

.sub-section .checkmark-item {
    margin-top: 0px;
    font-size: 0.9em;
}

/* ----------------------------- */
/* FLEX BOX
/* ----------------------------- */
.flex-box {
    display: flex;
    padding: 9px;
}

.flex-head {
    font-style: italic;
    border-bottom: solid 1px #C0C0C0;
    margin-top: 4px;
    font-size: 0.9em;
}

.flex-id {
    flex: 0 0 160px;
}

.flex-text {
    flex: 1 1 200px;
}

/* ----------------------------- */
/* CONTRACTS
/* ----------------------------- */
.primary-contract {
    margin-top: 20px;
    line-height: 30px;
}

.sub-contract-divider {
    border-top: solid 1px #393939;
    margin-top: 28px;
    padding-top: 14px;
    font-weight: bold;
}

.sub-contract {
    margin-left: 9px;
    padding: 6px 0px 4px 0px;
    border-left: solid 1px #F58220;
}

.contract-link {
    cursor: pointer;
    padding: 3px 6px 3px 6px;
}

.contract-link:hover {
    background-color: rgba(147, 149, 152, .4);
}

/* ----------------------------- */
/* LOGIC MODELS
/* ----------------------------- */
.local-condition-block {
    cursor: pointer;
}

/* ----------------------------- */
/* SWITCH
/* ----------------------------- */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-top: 12px;
    margin-bottom: 0px;
    left: 50px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #007932;
}

input:focus+.slider {
    box-shadow: 0 0 1px #007932;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

#active-text {
    line-height: 34px;
    vertical-align: bottom;
    margin-left: 50px;
}

/* ----------------------------- */
/* DATA ENTRY BOX
/* ----------------------------- */
#cover {
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background-color: rgba(255, 255, 255, 0.8);
    display: none;
}

.data-entry-box {
    position: absolute;
    top: 68px;
    width: 540px;
    height: 320px;
    z-index: 2001;
    border: solid 1px #191919;
    background-color: #FFFFFF;
    padding: 12px;
    margin-left: auto;
    margin-right: auto;
    display: none;
}

.data-entry-box h4 {
    background-color: #F58220;
    color: #FFFFFF;
    margin: -12px -12px 20px -12px;
    font-size: 0.9em;
    padding: 2px 6px 2px 6px;
}

.data-entry-box-close {
    float: right;
    border: solid 1px #595959;
    background-color: #595959;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 14px;
    padding: 0px 3px 1px 3px;
    margin-top: 2px;
    margin-right: -1px;
    border-radius: 3px;
    cursor: pointer;
}

#data-entry-box-close:hover {
    background-color: #909090;
}

/* ----------------------------- */
/* MEDIA OUTLETS
/* ----------------------------- */
.media-outlet {
    border: solid 1px #C0C0C0;
    border-radius: 6px 0px 6px 6px;
    padding: 9px;
    margin-bottom: 16px;
    background-color: #F6F6F6;
}

.media-outlet .delete {
    float: right;
    margin-top: -9px;
    margin-right: -9px;
    color: #dd634b;
    cursor: pointer;
}

.media-outlet h3 {
    font-size: 1.0em;
    font-weight: bold;
    color: #393939;
    margin: 0px 0px 9px 0px;
}

.media-outlet h3:hover {
    color: #dd634b;
}

.media-outlet-circulation {
    font-weight: normal;
    font-size: 0.86em;
}

.media-contact {
    display: flex;
    margin: 4px 0px 0px 4px;
    font-size: 0.9em;
}

.media-contact-edit {
    font-size: 14px;
    flex: 0 0 17px;
    padding-right: 3px;
    cursor: pointer;
}

.media-contact-edit:hover {
    color: #595959;
}

.media-contact-name {
    flex: 1 0 120px;
}

.media-contact-title {
    flex: 1 0 280px;
}

.media-contact-phone {
    flex: 1 0 90px;
}

.media-contact-email {
    flex: 1 0 200px;
}

.add-media-contact {
    margin-top: 5px;
    cursor: pointer;
    font-size: 15px;
    color: #595959;
    padding-left: 4px;
}

.add-media-contact:hover {
    color: #dd634b;
}

/* ----------------------------- */
/* OUTLET SEARCH
/* ----------------------------- */
.form-subblock {
    position: relative;
    margin: 28px 0px 10px 0px;
    padding: 9px;
    background-color: #FFFFFF;
    border: dotted 1px #999999;
}

.form-subblock h5 {
    background-color: #595959;
    color: #FFFFFF;
    padding: 1px 6px 1px 9px;
    margin: -9px -9px 16px -9px;
    border-radius: 4px 4px 0px 0px;
    letter-spacing: 1px;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
}


.form-subblock-close {
    position: absolute;
    top: 0px;
    right: 6px;
    margin-top: -2px;
    font-size: 1.2em;
    color: #FFFFFF;
    cursor: pointer;
}

#add-box-tabs {
    overflow: hidden;
    margin: -16px 0px 16px -12px;
    padding-right: 24px;
    font-weight: normal;
    font-size: 0.92em;
    line-height: 1em;
    letter-spacing: 1px;
    background: url('/images/cover.png');
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #595959;
    text-align: center;
}

#add-box-left-tab {
    float: left;
    width: 200px;
    background-color: #595959;
    color: #FFFFFF;
    padding: 3px 12px 4px 12px;
    margin-right: 4px;
    border-radius: 0px 6px 0px 0px;
}

#add-box-right-tab {
    float: left;
    width: 200px;
    background-color: #595959;
    color: #FFFFFF;
    padding: 3px 12px 4px 12px;
    border-radius: 6px 6px 0px 0px;
}

.add-box-deselected {
    background-color: #C0C0C0 !important;
    color: #696969 !important;
    cursor: pointer;
}

.add-box-deselected:hover {
    color: #FFFFFF !important;
    background-color: #F58220 !important;
}

.new-block {
    float: right;
    margin: 6px 0px 10px 0px;
    padding: 9px;
    background-color: #FFFFFF;
    border: dotted 1px #999999;
    width: 280px;
    height: 41px;
}

.new-block h4 {
    background-color: #FFFFFF;
    padding: 0px 6px 0px 6px;
    margin: -20px 0px 10px 10px;
    letter-spacing: 1px;
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    color: #292929;
    width: 20px;
}

.search-filter-box {
    margin-top: 10px;
    display: flex;
}

.search-filter-item {
    margin-bottom: 4px;
    margin-right: 20px;
}

.search-filter-item label {
    margin-right: 3px;
}

.input-search {
    flex: 1 0 200px;
}

#search-results {
    overflow: hidden;
    margin-bottom: 20px;
}

.search-result {
    float: left;
    width: 45%;
    border: solid 1px #A9A9A9;
    border-radius: 3px;
    padding: 3px 6px 3px 6px;
    margin: 6px 2% 0px 0px;
    cursor: pointer;
}

.search-result:hover {
    background-color: rgba(245, 130, 32, .8);
    color: #FFFFFF;
}

.outlet-circ {
    font-size: 0.90em;
    color: #6090A9;
}

#mediaoutlet-instructions {
    font-size: 0.8em;
    font-style: italic;
    margin: 6px 0px 0px 12px;
    color: #595959;
}

/* ----------------------------- */
/* STAFF HOURS
/* ----------------------------- */
#staff-hour-table {
    border-collapse: separate;
    box-sizing: border-box;
    border-spacing: 2px;
}

.staff-hours-row .btn {
    padding: 0.0rem 0.75rem 0.0rem 0.75rem;
}

.staff-id-col select,
.staff-id-col input {
    width: 240px;
}

.staff-category-col,
.staff-category-col select,
.staff-category-col input {
    width: 80px;
}

.staff-start-col select,
.staff-start-col input {
    width: 112px;
    text-align: right;
}

.staff-category-col {
    width: 80px !important;
    text-align: center;
}

.staff-direct-col,
.staff-admin-col,
.staff-support-col,
.staff-travel-col {
    width: 90px !important;
    text-align: center;
}

.staff-units {
    width: 90px;
    text-align: right;
}

.staff-total-col {
    width: 140px !important;
    text-align: center;
}

.staff-units-total {
    text-align: right;
    padding-right: 2px;
    width: 82px;
    color: #393939;
}

.overlap {
    background-color: #dd634b !important;
    color: #FFFFFF !important;
}

.disabled {
    color: #595959;
}

.staff-added {
    color: #595959;
    border-color: #A9A9A9;
}

/* ----------------------------- */
/* CALENDAR
/* ----------------------------- */

.datepicker-days {
    border: solid 1px #393939;
}

.datepicker-days table {
    border-collapse: separate;
    border-spacing: 1px;
    margin: 10px;
}

.datepicker-days th {
    text-align: center;
}

.datepicker-days td {
    background-color: #FFFFFF;
    height: 24px;
    width: 24px;
    text-align: right;
    padding: 2px 4px 2px 2px;
}

.datepicker-days .day {
    background-color: #F0F0F0;
    color: #F58220;
}

.datepicker-days .old {
    background-color: #FFFFFF;
    color: #999999;
}

.datepicker-days .disabled {
    color: #E0E0E0 !important;
    background-color: #FFFFFF;
}

/* ----------------------------- */
/* DEMOGRAPHICS TABLE
/* ----------------------------- */

.demographics-head {
    text-align: left;
    font-weight: normal;
    color: #F58220;
}

.demographics {
    margin: 6px 0px 0px 0px;
    overflow: hidden;
    width: 100%;
}

.demographics label {
    float: right;
    text-align: right;
    margin-right: 6px;
}

.demographics input {
    float: right;
    text-align: right;
    width: 106px;
}

.demographics-total {
    background-color: #E9E9E9;
    color: #595959;
}

.error {
    background-color: #f8cfcf;
    color: #dd634b;
}

.calculator {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 240px;
    padding: 10px;
    border-width: 0px 0px 1px 1px;
    border-style: solid;
    border-color: #909090;
    border-radius: 0px 0px 0px 4px;
    background-color: #F3F3F3;
}

.calculator h5 {
    margin: -10px -10px 9px -10px;
    padding: 3px 0px 2px 0px;
    background-color: #595959;
    color: #FFFFFF;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.calculator input {
    float: right;
    width: 98px;
    text-align: right;
    margin-right: 6px;
}

.calculator a {
    float: right;
    font-size: 18px;
    line-height: 22px;
}

/* ----------------------------- */
/* SYSTEM HEADER
/* ----------------------------- */
.system-header {
    margin: 12px 0px 0px 20px;
}

.cpg-name {
    color: #F58220;
    font-size: 1.1em;
    font-weight: 500;
    text-shadow: 1px 1px #393939;
}

#system-name {
    color: #FFFFFF;
    font-size: 1.3em;
    line-height: 1.0em;
    letter-spacing: 1px;
    font-weight: 100;
    padding: 0px;
    margin: 0px;
}

#system-account {
    padding: 0px;
    margin: 6px 0px 0px 6px;
    line-height: 1.0em;
    font-weight: 100;
    font-size: 1.1em;
}

.account-link {
    font-size: 0.9em;
    padding: 0px 3px 0px 3px;
    margin: 6px 0px 0px 0px;
    line-height: 1.1em;
    font-weight: 100;
    color: #393939;
}

.account-link:hover {
    color: #000000;
    text-decoration: none;
}

#system-account .dropdown-menu {}

#system-account .account-select-option a {
    margin: 0px 20px 0px 10px;
    color: #393939;
    line-height: 1.0em;
    letter-spacing: 1px;
    font-weight: 100;
    font-size: 1.1em;
}

.account-dropdown {
    padding: 0px;
    margin: 6px 0px 0px 0px;
    line-height: 1em;
    letter-spacing: 1px;
    font-weight: 100;
    cursor: pointer;
}

.account-dropdown:hover {
    color: #FFFFFF;
}

/* ----------------------------- */
/* TOP NAV
/* ----------------------------- */
#top-nav-bar {
    height: 86px;
}

#top-nav {
    float: right;
    margin: 16px 0px 0px 0px;
    background-color: #FFFFFF;
}

.nav-link {
    color: #494949 !important;
    vertical-align: middle;
}

.fal {
    color: #fbb040;
    font-size: 1.4em;
    vertical-align: middle;
    margin-right: 3px;
}

.nav-item {
    position: relative;
}

.dropdown-item {
    font-size: 19px !important;
    line-height: 24px;
}

.dropdown-item:hover {
    color: rgba(245, 130, 32, .8);
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

/* ----------------------------- */
/* SPF NAVIGATION
/* ----------------------------- */

#spf-sidebar {
    min-width: 260px;
    max-width: 260px;
    min-height: 100vh;
    transition: all 0.3s;
    background-color: #FFFFFF;
}

#spf-sidebar.active {
    min-width: 90px;
    max-width: 90px;
    text-align: center;
}

#spf-content {
    margin: 86px 0px 6px 0px;
    border-top: solid 1px #CCCCCC;
}

#spf-sidebar img {
    display: block;
    margin: 0px;
    padding: 0px;
}

.spf-item {
    display: block;
    width: 256px;
    height: 80px;
    border-bottom: solid 1px #909090;
    padding: 10px 6px 6px 6px;
    margin: 0px 0px 0px 0px;
    cursor: pointer;
    line-height: 1em;
    color: #595959;
}

.spf-disabled {
    cursor: default !important;
}

.spf-thin {
    width: 90px !important;
}

.spf-item:hover {
    color: #292929;
    text-decoration: none;
}

.spf-item i {
    float: left;
    font-size: 38px;
    width: 48px;
    margin: 10px 12px 10px 9px;
}

.spf-disabled i {
    color: #A0A0A0 !important;
}

.spf-item-description {
    padding-left: 70px;
}

.spf-item h4,
.spf-item-admin h4 {
    font-weight: bold;
    margin-top: 0px;
    color: #393939;
}

.spf-disabled h4 {
    color: #909090 !important;
}

.spf-text {
    height: 40px;
    font-size: 0.72em;
    overflow: hidden;
}

.spf-disabled .spf-text {
    color: #909090 !important;
}

#left-side-panel-close {
    float: right;
    background-color: #F58220;
    color: #FFFFFF;
    padding: 3px 2px 4px 2px;
    border-radius: 16px 0px 0px 16px;
    margin: 5px 0px 0px 0px;
    width: 14px;
    cursor: pointer;
}


#spf-sidebar-admin {
    min-width: 140px;
    max-width: 140px;
    margin-top: 86px;
    height: 100%;
    background-color: #FFFFFF;
    border-right: solid 1px #909090;
}

.spf-item-admin {
    display: block;
    width: 136px;
    height: 40px;
    padding: 10px 6px 6px 6px;
    margin: 0px 0px 0px 0px;
    cursor: pointer;
    line-height: 1em;
    color: #595959;
}

#admin-content {
    width: 100%;
    height: 100%;
    padding-left: 140px;
}

/* ----------------------------- */
/* CHANNEL NAVIGATION
/* ----------------------------- */
#channel-nav {
    margin: 0px;
    padding: 0px 0px 0px 0px;
    width: 100%;
}

#channel-nav-box {
    height: 32px;
}

#step-action-nav {
    margin: -10px 24px 0px 0px;
    font-size: 14px;
    overflow: hidden;
    text-transform: uppercase;
    width: 100%;
}

#step-action-nav li {
    float: right;
    color: #F58220;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

#step-action-nav a {
    color: #595959;
}

#step-action-nav a:hover {
    color: rgba(245, 130, 32, .8);
}

.step-nav-divider {
    float: right;
    color: #CCCCCC;
    margin: 0px 10px 0px 10px;
}

.subnav-assessment {
    color: #dd634b !important;
}

.subnav-capacity {
    color: #64add1 !important;
}

.subnav-plan {
    color: #007932 !important;
}

.subnav-implement {
    color: #fbb040 !important;
}

.subnav-evaluate {
    color: #6d70a6 !important;
}

.subnav-selected:hover {
    text-decoration: none;
    color: #595959 !important;
    cursor: default;
}

/* ----------------------------- */
/* TAB NAVIGATION
/* ----------------------------- */
.nav-tabs .nav-link {
    color: #C0C0C0 !important;
    background-color: #F0F0F0;
    border-color: #E0E0E0;
    margin-left: 2px;
}

.nav-tabs .nav-link:hover {
    color: #909090 !important;
    background-color: #EAEAEA;
    border-color: #E0E0E0;
}

.nav-tabs .nav-link.active {
    color: #000000 !important;
}

.nav-tabs .nav-link.active:hover {
    color: #000000 !important;
    background-color: #FFFFFF;
    border-color: #E0E0E0;
    cursor: default;
}

.tab-content {
    margin-top: 6px;
}

/* ----------------------------- */
/* MODALS
/* ----------------------------- */

.modal-header {
    background-color: #595959;
}

.modal-title {
    color: #FFFFFF;
    font-style: normal;
    margin: 0px;
    padding: 0px;
}

/* ----------------------------- */
/* FOOTER
/* ----------------------------- */
#footer {
    width: 100%;
    height: 26px;
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    border-color: #696969;
    font-size: 14px;
}

#cpg-home {
    float: right;
    margin: 0px 32px 0px 0px;
}

#cpg-home a {
    color: #FFFFFF;
    font-size: 0.86em;
}

#cpg-home a:hover {
    color: #393939;
    text-decoration: underline;
}


#left-column-nav {
    width: 100%;
    margin: 0px;
    height: 14px;
}

#left-column img {
    display: block;
    margin: 0px;
    padding: 0px;
}

/* ----------------------------- */
/* LOG-IN PAGE
/* ----------------------------- */
.cpg-login {
    background: url(/images/login_back.jpg) no-repeat;
    background-size: cover;
}

.cpg-login-box {
    width: 520px;
    height: 500px;
    position: absolute;
    left: 80px;
    bottom: 80px;
    background-color: #FFFFFF;
    padding: 10px;
}

.cpg-message-box {
    width: 600px;
    height: 500px;
    background-color: #FFFFFF;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border: solid 1px #909090;
}

.cpg-login-box .version {
    float: right;
    font-size: 1.4em;
    margin: 10px;
    color: #909090;
}

.cpg-login-head {
    text-align: center;
}

.cpg-login-head h2 {
    font-size: 1.5em;
    margin: 20px 0px 0px 0px;
    line-height: 1.1em;
}

.cpg-login-head h3 {
    font-size: 1.2em;
    margin: 6px 0px 0px 0px;
    color: #393939;
}

.cpg-login-box form {
    margin: 20px;
}

.cpg-login-item {
    width: 360px;
    margin: 9px auto 0px auto;
    position: relative;
}

.cpg-login-item i {
    position: absolute;
    top: 9px;
    right: 6px;
}

.cpg-login-input {
    width: 100%;
    text-align: left;
    font-size: 18px;
}

.icon {
    color: #f58220;
}

.cpg-remember-me {
    font-size: 0.8em;
    text-align: center;
}

.cpg-password-reset {
    font-size: 0.9em;
    text-align: center;
}

.cpg-login-submit {
    padding-left: 14px;
}

/* ----------------------------- */
/* ADMIN TOOL
/* ----------------------------- */
.admin-tenant-name {
    text-transform: uppercase;
    font-size: 0.9em;
}

.admin-table tr {
    cursor: pointer;
}

.admin-table tr:hover {
    background-color: rgba(147, 149, 152, .4);
}

/* ----------------------------- */
/* ACCOUNT PAGES
/* ----------------------------- */
.staff-roles i {
    margin-right: 4px;
}

/* ----------------------------- */
/* STAFF MEMBERS
/* ----------------------------- */
.staff-table tr {
    cursor: pointer;
}

.staff-table tr:hover {
    background-color: rgba(147, 149, 152, .4);
}

/* ----------------------------- */
/* PLAN CHANNEL
/* ----------------------------- */
.plan-overview-head {
    height: 44px;
    vertical-align: bottom;
}

.plan-overview-head div {
    vertical-align: bottom;
}

/* ----------------------------- */
/* MESSAGING
/* ----------------------------- */
.message-table tr {
    cursor: pointer;
}

.message-table tr:hover {
    background-color: rgba(147, 149, 152, .4);
}

.message-table i {
    font-size: 36px;
    width: 36px;
    margin: 16px auto 0px auto;
    display: block;
}

.message-icon {
    float: left;
    width: 50px;
    text-align: center;
}

.message-icon i {
    font-size: 40px;
    margin: 0px auto 0px auto;
    display: block;
}

.message-header {
    margin-bottom: 5px;
    width: 100%;
    display: flex;
}

.message-header span {
    flex: 0 0 78px;
    font-weight: bold;
}

.message-body {
    font-style: italic;
    margin-top: 12px;
}

.message-title {
    color: #339033;
}

.message-reply {
    margin-top: 12px;
    border-top: dotted 1px #CCCCCC;
    padding-top: 9px;
}

.message-reply-head {
    color: #007932;
}

.message-reply-text {
    margin-top: 9px;
    font-style: italic;
}

.message-read {
    color: #7A7A7A;
}

.message-read .message-reply-head {
    color: #7A7A7A;
}

.dashboard-message,
.dashboard-announcement {
    margin: 0px 0px 12px 0px;
    padding: 6px 6px 16px 6px;
    border-bottom: dotted 1px #C0C0C0;
}

.dashboard-message {
    cursor: pointer;
}

.dashboard-message {
    cursor: pointer;
    position: relative;
}

.dashboard-message:last-child,
.dashboard-announcement:last-child {
    padding-bottom: 0px;
    border-bottom: none;
}

.dashboard-message .tooltiptext {
    visibility: hidden;
    background-color: rgba(221, 99, 75, .5);
    color: #191919;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 6px;
    border-radius: 3px;
    position: absolute;
    z-index: 1;
}

.dashboard-message:hover {
    color: #E0E0E0;
}

.dashboard-message:hover .tooltiptext {
    visibility: visible;
}

.dashboard-message-subject {
    font-weight: bold;
    font-size: 1.1em;
    margin-top: 2px;
}

.dashboard-message-date {
    font-size: 0.8em;
    font-style: italic;
}

.dashboard-message-body {
    margin-top: 7px;
}

/* ----------------------------- */
/* RESPONSIVE
/* ----------------------------- */
@media (max-width: 1290px) {
    body {
        font-size: 16px;
        line-height: 20px;
    }

    input {
        font-size: 16px;
        line-height: 20px;
    }

    select {
        font-size: 16px;
        line-height: 20px;
    }

    textarea {
        font-size: 16px;
        line-height: 20px;
    }

    .cpg-date {
        font-size: 15px;
    }

    .dropdown-item {
        font-size: 16px !important;
        line-height: 22px;
    }

    #spf-content {
        font-size: 0.9em;
    }

    .spf-item {
        width: 220px;
        height: 64px;
    }

    .spf-thin {
        height: 64px;
    }

    #spf-sidebar {
        min-width: 260px;
        max-width: 260px;
    }

    #cpg-content.wide {
        margin-left: 260px
    }

    .cpg-login-box {
        width: 500px;
        height: 480px;
        left: 40px;
        bottom: 40px;
        padding: 10px;
    }
}

@media (max-width: 768px) {
    #spf-sidebar {
        min-width: 90px;
        max-width: 90px;
        text-align: center;
    }

    .spf-item {
        width: 88px;
    }

    .spf-item h4 {
        display: none;
    }

    .spf-text {
        display: none;
    }

    #cpg-content.wide {
        margin-left: 90px;
    }

    #left-side-panel-close {
        display: none;
    }

    .sm-hide {
        display: none;
    }

    .cpg-login-box {
        width: 440px;
        height: 420px;
        left: 40px;
        bottom: 40px;
        padding: 10px;
    }
}

.tag {
    border: 1px solid #ccc;
    display: inline-flex;
    height: 2em;
    justify-content: center;
    line-height: 1.8;
    padding-left: 0.75em;
    padding-right: 0.75em;
    white-space: nowrap;
    margin-right: .25em;
    margin-bottom: .25em;

    .close {
        line-height: 1.5;
        margin-left: .5em;
    }
}

.datepicker-switch, .datepicker .prev, .datepicker .next {
    cursor: pointer;
    padding: 0.25rem !important;
    border-radius: 0.25rem !important;
    text-align: center;
}

.datepicker-switch:hover, .datepicker .prev:hover, .datepicker .next:hover {
    background-color: #F58220 !important;
    color: #FFFFFF !important;
}

.datepicker-months span.month, .datepicker-years span.year, .datepicker-decades span.decade, .datepicker-centuries span.century {
    cursor: pointer;
    padding: 0.25rem !important;
    border-radius: 0.25rem !important;
}

.datepicker-months span.month:hover, .datepicker-years span.year:hover, .datepicker-decades span.decade:hover, .datepicker-centuries span.century:hover {
    background-color: #F58220 !important;
    color: #FFFFFF !important;
}

.participants-table tr:hover {
    background-color: rgba(147, 149, 152, .4);
}
